import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import styles from './LabeledIcon.module.scss';
import i18n from '../../i18n';
import classNames from 'classnames';

const LabeledIcon = ({
    type,
    color,
    label,
    extraLabel,
    href,
    onClick,
    isVertical,
    isHorizontal,
    fontSize,
    labelMargin,
    extraLabelMod,
    modifiers,
}) => {
    const colorHexOrName = color === 'black800' ? '#181818' : color;

    return (
        <div
            className={
                modifiers.map((x) => x + ' ') + ' ' + styles['LabeledIcon']
            }>
            {href && (
                <div
                    className={classNames(
                        styles['LabeledIcon__Link'],
                        {
                            [styles['LabeledIcon__Link--IsVertical']]:
                                isVertical === true,
                        },
                        {
                            [styles['LabeledIcon__Link--IsHorizontal']]:
                                isHorizontal === true,
                        }
                    )}>
                    <a
                        className={styles['LabeledIcon__Anchor']}
                        href={href}
                        onClick={onClick}
                        {...(type === 'bookmarkFilled'
                            ? { 'aria-live': 'polite' }
                            : {})}>
                        <span className="sr-only">
                            {type === 'bookmarkFilled'
                                ? i18n.t('MyList.bookmarkLabel', {
                                      amount: extraLabel,
                                  })
                                : label}
                        </span>
                    </a>
                    <Icon
                        type={type}
                        color={color}
                        modifiers={[styles['LabeledIcon__LinkIcon']]}
                        size="large"
                    />

                    <span
                        style={{ color: colorHexOrName }}
                        className={classNames(
                            labelMargin,
                            styles['LabeledIcon__LinkLabel']
                        )}>
                        {fontSize ? (
                            <span className={fontSizeAndLabelMargin}>
                                {label}
                            </span>
                        ) : (
                            label
                        )}
                    </span>
                    {extraLabel && (
                        <span
                            style={{ color: colorHexOrName }}
                            className={classNames(
                                extraLabelMod,
                                styles['LabeledIcon__LinkLabel']
                            )}>
                            {extraLabel}
                        </span>
                    )}
                </div>
            )}

            {!href && onClick && (
                <button
                    className={classNames(
                        styles['LabeledIcon__Link'],
                        {
                            [styles['LabeledIcon__Link--IsVertical']]:
                                isVertical === true,
                        },
                        {
                            [styles['LabeledIcon__Link--IsHorizontal']]:
                                isHorizontal === true,
                        }
                    )}
                    onClick={onClick}
                    title={label}>
                    <Icon
                        type={type}
                        color={color}
                        modifiers={[styles['LabeledIcon__LinkIcon']]}
                        size="large"
                    />

                    <span
                        style={{ color: colorHexOrName }}
                        className={classNames(
                            extraLabelMod,
                            labelMargin,
                            styles['LabeledIcon__LinkLabel']
                        )}>
                        {fontSize ? (
                            <span className={fontSizeAndLabelMargin}>
                                {label}
                            </span>
                        ) : (
                            label
                        )}
                    </span>
                    {extraLabel && (
                        <span
                            style={{ color: colorHexOrName }}
                            className={classNames(
                                extraLabelMod,
                                styles['LabeledIcon__LinkLabel']
                            )}>
                            {extraLabel}
                        </span>
                    )}
                </button>
            )}
        </div>
    );
};

LabeledIcon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    extraLabel: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    isVertical: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    fontSize: PropTypes.string,
    labelMargin: PropTypes.string,
    extraLabelMod: PropTypes.array,
    modifiers: PropTypes.array,
};

LabeledIcon.defaultProps = {
    type: 'close',
    color: 'white',
    label: '',
    extraLabel: '',
    onClick: () => {},
    href: '',
    isVertical: false,
    isHorizontal: false,
    fontSize: '',
    labelMargin: '',
    extraLabelMod: [],
    modifiers: [],
};

export default LabeledIcon;
