import { trackEvent } from './datalayer';
import { capitalizeFirstLetter } from './helpers';

export const MY_LIST_KEY_OBJECTS = 'objects';
export const MY_LIST_KEY_EVENT_OCCURRENCES = 'eventOccurrences';
export const MY_LIST_KEY_GUIDES = 'guides';

const LOCALSTORAGE_KEY = 'myList';

const getStoredIdsObject = () => {
    try {
        const parse = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
        return {
            [MY_LIST_KEY_OBJECTS]: parse[MY_LIST_KEY_OBJECTS]
                ? parse[MY_LIST_KEY_OBJECTS]
                : [],
            [MY_LIST_KEY_EVENT_OCCURRENCES]: parse[
                MY_LIST_KEY_EVENT_OCCURRENCES
            ]
                ? parse[MY_LIST_KEY_EVENT_OCCURRENCES]
                : [],
            [MY_LIST_KEY_GUIDES]: parse[MY_LIST_KEY_GUIDES]
                ? parse[MY_LIST_KEY_GUIDES]
                : [],
        };
    } catch (e) {
        return null;
    }
};

const saveIdsObject = (idsObject) => {
    if (localStorage) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(idsObject));
    }
};

const getOrInitIdsObject = () => {
    let idsObject = getStoredIdsObject();
    if (!idsObject) {
        idsObject = {};
        saveIdsObject(idsObject);
    }
    return idsObject;
};

const updateIdsObject = (idsObject, key, idList) => {
    return {
        ...idsObject,
        [key]: idList,
    };
};

const getIdListFromObject = (idsObject, key) => {
    return idsObject[key] ? idsObject[key] : [];
};

const updateAndSaveIdList = (key, idList) => {
    const idsObject = getOrInitIdsObject();
    const newIdsObject = updateIdsObject(idsObject, key, idList);
    saveIdsObject(newIdsObject);

    const myListUpdated = new CustomEvent('myListUpdated', {
        detail: { id: 3 },
    });
    window.dispatchEvent(myListUpdated);
};

const getIdsByKey = (key) => {
    const idsObject = getOrInitIdsObject();
    return getIdListFromObject(idsObject, key);
};

const savePage = (key, id) => {
    let idList = getIdsByKey(key);

    if (idList.includes(id)) return;

    idList.push(id);
    updateAndSaveIdList(key, idList);
};

const isPageIdSaved = (storageKey, id) => {
    const idList = getIdsByKey(storageKey);

    return idList.includes(id);
};

const getSavedIdsObject = () => {
    return getOrInitIdsObject();
};

const countSavedItems = () => {
    const idsObject = getStoredIdsObject();
    if (!idsObject) {
        return 0;
    }
    return Object.values(idsObject).reduce((a, b) => a + b.length, 0);
};

const removeSavedId = (key, id) => {
    let idList = getIdsByKey(key);
    idList = idList.filter((item) => item !== id);
    updateAndSaveIdList(key, idList);
};

/**
 * @param key
 * @param id
 * @returns {boolean} - Save success status
 */
const saveOrRemoveId = (key, id, title, category) => {
    const idList = getIdsByKey(key);
    if (idList.includes(id)) {
        removeSavedId(key, id);
        return false;
    }
    trackEvent('save', 'Engagement', 'Save', capitalizeFirstLetter(key), {
        objectHeadline: title,
        objectCategory: category,
    });
    savePage(key, id);
    return true;
};

const generateUrl = (url) => {
    const idsObject = getStoredIdsObject();
    const simpleObject = {
        o: idsObject[MY_LIST_KEY_OBJECTS] || [],
        e: idsObject[MY_LIST_KEY_EVENT_OCCURRENCES] || [],
        g: idsObject[MY_LIST_KEY_GUIDES] || [],
    };
    const storageB64 = btoa(JSON.stringify(simpleObject));
    return url + '?share=' + storageB64;
};

const decodeIdsObject = (b64string) => {
    try {
        const urlObj = JSON.parse(atob(b64string));
        return {
            [MY_LIST_KEY_OBJECTS]: urlObj.o,
            [MY_LIST_KEY_EVENT_OCCURRENCES]: urlObj.e,
            [MY_LIST_KEY_GUIDES]: urlObj.g,
        };
    } catch (e) {
        return {};
    }
};

export {
    savePage,
    isPageIdSaved,
    getSavedIdsObject,
    countSavedItems,
    getIdsByKey,
    removeSavedId,
    saveOrRemoveId,
    generateUrl,
    decodeIdsObject,
};
