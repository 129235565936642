import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ButtonRound.module.scss';
import { ucFirst } from '../../utils/caseconverters';
import Icon from '../../components/Icon';
import { allIcons } from '../Icon/Icon';

const ButtonRound = ({
    type,
    href,
    onClick,
    modalSize,
    iconHoverColor,
    label,
}) => {
    const AnchorOrButton = href ? 'a' : 'button';

    const modifiers = [styles['ButtonRound--Active']];

    return (
        !!type && (
            <div className={styles['ButtonRound']}>
                <AnchorOrButton
                    href={href}
                    onClick={onClick}
                    className={styles['ButtonRound__Link']}>
                    <span className="sr-only">{label}</span>
                </AnchorOrButton>

                <Icon
                    type={type}
                    color="white"
                    size="large"
                    round={true}
                    modalSize={modalSize}
                    hoverColor={iconHoverColor}
                    modifiers={modifiers}
                />
            </div>
        )
    );
};

ButtonRound.propTypes = {
    href: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(allIcons)).isRequired,
    modalSize: PropTypes.bool,
    iconHoverColor: PropTypes.oneOf(['white', 'black800', 'pink']),
    modifiers: PropTypes.array,
    label: PropTypes.string,
};

ButtonRound.defaultProps = {
    modalSize: false,
    iconHoverColor: null,
    href: '',
    onClick: () => {},
    type: 'facebook',
    modifiers: [],
    label: '',
};

export default ButtonRound;
