// Accept tab key press event and a ref to the container with items that should be tabbable.
const isolateTabs = (tabEvent, ref) => {
    if (!ref?.current) {
        return;
    }

    const focusableModalElements = ref.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );

    const firstElement = focusableModalElements[0];
    const lastElement =
        focusableModalElements[focusableModalElements.length - 1];

    if (!tabEvent.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return tabEvent.preventDefault();
    }

    if (tabEvent.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        tabEvent.preventDefault();
    }
};

export { isolateTabs };
